//
// Variables
// --------------------------------------------------


//== Elements
//
//## for buttons, label, forms, navigations, badges, labels, dropdown
$element-font-size:           .8125rem !default; // 13px
$element-font-size-lg:        .9375rem !default; // 15px
$element-font-size-sm:        .6875rem !default; // 11px


//== Forms
//
//##
// custom forms
$custom-control-gutter:   1.5rem !default;
$custom-control-spacer-x: 1rem !default;
$custom-control-spacer-y: .25rem !default;

$custom-control-indicator-size:       1rem !default;
$custom-control-indicator-bg:         #d5d5d5 !default;
$custom-control-indicator-bg-size:    50% 50% !default;
$custom-control-indicator-box-shadow: inset 0 1px 1px rgba(#000, .05) !default;

$custom-control-disabled-cursor:             $cursor-disabled !default;
$custom-control-disabled-indicator-bg:       $gray-lighter !default;
$custom-control-disabled-description-color:  $gray-light !default;

$custom-control-checked-indicator-color:      #fff !default;
$custom-control-checked-indicator-bg:         $brand-primary !default;
$custom-control-checked-indicator-box-shadow: none !default;

$custom-control-focus-indicator-box-shadow: 0 0 0 1px $body-bg, 0 0 0 3px $input-border-focus !default;

$custom-control-active-indicator-color:      #fff !default;
$custom-control-active-indicator-bg:         lighten($brand-primary, 35%) !default;
$custom-control-active-indicator-box-shadow: none !default;

$custom-checkbox-radius: $border-radius-base !default;
$custom-checkbox-checked-icon: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$custom-control-checked-indicator-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"), "#", "%23") !default;

$custom-checkbox-indeterminate-bg: $brand-primary !default;
$custom-checkbox-indeterminate-indicator-color: $custom-control-checked-indicator-color !default;
$custom-checkbox-indeterminate-icon: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#{$custom-checkbox-indeterminate-indicator-color}' d='M0 2h4'/%3E%3C/svg%3E"), "#", "%23") !default;
$custom-checkbox-indeterminate-box-shadow: none !default;

$custom-radio-radius: 50% !default;
$custom-radio-checked-icon: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$custom-control-checked-indicator-color}'/%3E%3C/svg%3E"), "#", "%23") !default;

$custom-select-padding-x:          $padding-base-horizontal  !default;
$custom-select-padding-y:          $padding-base-vertical !default;
$custom-select-indicator-padding:   1em !default; // Extra padding to account for the presence of the background-image based indicator
$custom-select-line-height:         $input-height-base !default;
$custom-select-color:               $input-color !default;
$custom-select-disabled-color:      $gray-light !default;
$custom-select-bg:            $input-bg !default;
$custom-select-disabled-bg:   tint($gray-lighter, 50%) !default;
$custom-select-bg-size:       8px 10px !default; // In pixels because image dimensions
$custom-select-indicator-color: $input-color !default;
$custom-select-indicator:     url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIxMHB4IiBoZWlnaHQ9IjZweCIgdmlld0JveD0iLTE1IDE3IDEwIDYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgLTE1IDE3IDEwIDYiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGlkPSJwYXRoLTEiIGZpbGw9IiM0NDQ0NDQiIGQ9Ik0tMTAsMjNsLTUtNmgxMEwtMTAsMjN6Ii8+PC9zdmc+) !default;
$custom-select-border-width:  $btn-border-width !default;
$custom-select-border-color:  $input-border !default;
$custom-select-border-radius: $border-radius-base !default;

$custom-select-focus-border-color: $input-border-focus !default;
$custom-select-focus-box-shadow:   none !default;

$custom-select-sm-font-size:  $element-font-size !default;
$custom-select-lg-font-size:  1rem !default;

$custom-file-height:           $input-height-base !default;
$custom-file-width:            220px !default;
$custom-file-focus-box-shadow: 0 0 0 1px $body-bg, 0 0 0 2px $input-border-focus !default;

$custom-file-padding-x:     $padding-base-vertical !default;
$custom-file-padding-y:     $padding-base-horizontal !default;
$custom-file-line-height:   $line-height-base !default;
$custom-file-color:         $input-color !default;
$custom-file-bg:            transparent !default; // $input-bg
$custom-file-border-width:  1px !default;
$custom-file-border-color:  $input-border !default;
$custom-file-border-radius: $border-radius-base !default;
$custom-file-box-shadow:    inset 0 1px 1px rgba(#000, .05) !default;
$custom-file-button-color:  $link-color !default;
$custom-file-button-bg:     $input-bg !default;
$custom-file-text: (
  placeholder: (
    en: "or Drag Files"
  ),
  button-label: (
    en: "Browse"
  )
) !default;


//== Grid system
//
//##
// use for loop only
$grid-breakpoints: (
  xs: 0,
  sm: $screen-xs, // 480px
  md: $screen-sm, // 768px
  lg: $screen-md, // 992px
  xl: $screen-lg // 120px
) !default;
// Spacing
$spacers: (
  0: 0,
  1: 5px,
  2: 11px,
  3: 15px,
  4: 22px,
  5: 44px
);
// This variable affects the `.h-*` and `.w-*` classes.
$sizes: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
  auto: auto
) !default;


//== Bridges
//
//## Variables that use in bs v4.x that not exist on v3. Currenrly used only on _utilities.scss.
$font-weight-normal: normal !default;
$font-weight-bold: bold !default;

$border-radius:     $border-radius-base !default; // alias variable
$zindex-fixed:      1030 !default;
$zindex-sticky:     1030 !default;