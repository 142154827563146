@import "mixins/breakpoints";
@import "mixins/text-emphasis";
@import "mixins/text-hide";
@import "mixins/text-truncate";
@import "mixins/float";
@import "mixins/size";
@import "mixins/visibility";

//
// Interaction states
// --------------------------------------------------

@mixin hover {
  &:hover {
    @content
  }
}
@mixin hover-focus {
  &:hover,
  &:focus,
  .focus {
    @content
  }
}
@mixin active {
  &:active,
  &.active {
    @content
  }
}
@mixin hover-focus-active {
  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active {
    @content
  }
}


//
// Contextual backgrounds
// --------------------------------------------------

// [converter] $parent hack
@mixin bg-variant($parent, $color) {
  #{$parent} {
    background-color: $color !important;
  }
  a#{$parent}:hover,
  a#{$parent}:focus {
    background-color: darken($color, 10%) !important;
  }
}


//
// Alternate buttons
// --------------------------------------------------

@mixin button-variant($color, $background, $border) {
  $active-background: darken($background, 5%);
  $active-border: darken($border, 5%);

  color: $color;
  background-color: $background;
  border-color: $border;

  @include hover-focus {
    color: $color;
    background-color: $active-background;
        border-color: $active-border;
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: $active-background;
        border-color: $active-border;
    // Remove the gradient for the pressed/active state
    background-image: none;
    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: $active-background;
        border-color: $active-border;
    }
  }

  &.disabled,
  &:disabled {
    &:focus,
    &.focus {
      background-color: $background;
          border-color: $border;
    }
    @include hover {
      background-color: $background;
          border-color: $border;
    }
  }
}

@mixin button-outline-variant($color, $color-hover: #fff) {
  color: $color;
  background-color: transparent;
  border-color: $color;

  .badge {
    color: $color-hover;
    background-color: $color;
  }

  @include hover-focus {
    color: $color-hover;
    background-color: $color;
    border-color: $color;

    .badge {
      color: $color;
      background-color: $color-hover;
    }
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;

    .badge {
      color: $color-hover;
      background-color: $color;
    }
  }

  &:active,
  &.active,
  .show > &.dropdown-toggle {
    color: $color-hover;
    background-color: $color;
    border-color: $color;

    .badge {
      color: $color;
      background-color: $color-hover;
    }
  }
}

// make button raised
@mixin button-raised-variant($background) {
  &.btn-raised {
    @include box-shadow(0 2px 20px rgba($background, .3));

    @include hover-focus {
      @include box-shadow(0 2px 20px rgba($background, .3), inset 0 -3px rgba(black, .12), inset 0 0 0 100px rgba(black, .04));
    }
  }
}


// Alerts
@mixin alert-variant($background, $border, $text-color) {
  background-color: $background;
  border-color: $border;
  color: $text-color;

  hr {
    border-top-color: darken($border, 5%);
  }
  a {
    color: $text-color;
    text-decoration: underline;

    @include hover-focus {
      color: darken($text-color, 3%);
    }
  }
}


// Labels
// outline
@mixin label-outline-variant($color) {
  color: $color;
  box-shadow: 0 0 0 1px $color;

  &[href] {
    &:hover,
    &:focus {
      color: darken($color, 10%);
    }
  }
}
// striped
@mixin label-striped-variant($color) {
  color: $gray;
  background-color: $brand-default;
  border-left: 2px solid $color;
  @include border-left-radius(0);

  &[href] {
    &:hover,
    &:focus {
      color: $text-color;
      background-color: darken($brand-default, 5%);
    }
  }
}


// Badges

@mixin badge-variant($color) {
  background-color: $color;

  &[href] {
    &:hover,
    &:focus {
      background-color: darken($color, 10%);
    }
  }
}


// Switcher

@mixin switcher-variant($color) {
  .switcher-input:checked {
    + .switcher-indicator,
    + .switcher-indicator:before {
      border-color: $color;
    }
    + .switcher-indicator {
      background-color: $color;
    }
  }
}


// Panel variants

@mixin panel-variant($background, $border) {
  background-color: $background;
  border-color: $border;

  .panel-heading,
  .panel-footer {
    background-color: transparent;
  }
}

@mixin panel-outline-variant($color) {
  background-color: transparent;
  border-color: $color;
}

//
// Inverse text within a panel for use with dark backgrounds
//

@mixin panel-inverse {
  color: rgba(#fff, .87);

  .panel-heading,
  .panel-footer,
  .list-group-item {
    background-color: transparent;
    border-color: rgba(#fff, .1);
  }
  .table td,
  .table th {
    background-color: transparent;
    border-color: rgba(#fff, .05);
  }
  .panel-heading,
  .panel-footer,
  .panel-title,
  .panel-blockquote {
    color: #fff;
  }
  .panel-link,
  .panel-text,
  .panel-subtitle,
  .panel-blockquote .blockquote-footer {
    color: rgba(#fff, .87);
  }
  .text-muted,
  .table > tfoot > tr > td,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > thead > tr > th {
    color: rgba(#fff, .65);
  }
  .panel-heading > a,
  .panel-title > a {
    @include hover-focus {
      color: #fff;
    }
  }
  .panel-link {
    @include hover-focus {
      color: #fff;
    }
  }

  .panel-tools > li > a {
    color: rgba(#fff, .65);

    @include hover-focus {
      color: #fff;
    }
  }

  .table > tbody> tr.active> td,
  .table > tbody> tr.active> th,
  .table > tbody > tr > td.active,
  .table > tbody > tr > th.active {
    background-color: rgba(#fff, .15)
  }
  .table-hover > tbody > tr:hover {
    background-color: rgba($brand-primary, .15);
  }
}

// perfect-scrollbar
@mixin ps-custom-theme ($bar-container-hover-bg, $bar-hover-bg, $rail-hover-bg) {
  &.ps-in-scrolling.ps-x {
    > .ps-scrollbar-x-rail {
      background-color: $bar-container-hover-bg;
      opacity: 0.9;
    }
    > .ps-scrollbar-x-rail > .ps-scrollbar-x {
      background-color: $bar-hover-bg;
    }
  }

  &.ps-in-scrolling.ps-y {
    > .ps-scrollbar-y-rail {
      background-color: $bar-container-hover-bg;
      opacity: 0.9;
    }
    > .ps-scrollbar-y-rail > .ps-scrollbar-y {
      background-color: $bar-hover-bg;
    }
  }

  > .ps-scrollbar-x-rail > .ps-scrollbar-x {
    background-color: $rail-hover-bg;
  }
  > .ps-scrollbar-y-rail > .ps-scrollbar-y {
    background-color: $rail-hover-bg;
  }

  &.ps-in-scrolling.ps-x:hover {
    > .ps-scrollbar-x-rail {
      background-color: $bar-container-hover-bg;
      opacity: 0.9;
      > .ps-scrollbar-x {
        background-color: $bar-hover-bg;
      }
    }
  }
  &.ps-in-scrolling.ps-y:hover {
    > .ps-scrollbar-y-rail {
      background-color: $bar-container-hover-bg;
      opacity: 0.9;
      > .ps-scrollbar-y {
        background-color: $bar-hover-bg;
      }
    }
  }

  &:hover {
    > .ps-scrollbar-x-rail,
    > .ps-scrollbar-y-rail {
      opacity: 0.3;
    }
    > .ps-scrollbar-x-rail:hover {
      background-color: $bar-container-hover-bg;
      opacity: 0.9;
      > .ps-scrollbar-x {
        background-color: $bar-hover-bg;
      }
    }
    > .ps-scrollbar-y-rail:hover {
      background-color: $bar-container-hover-bg;
      opacity: 0.9;
      > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
        background-color: $bar-hover-bg;
      }
    }
  }
}


// sliders
@mixin slider-context ($color) {
  .slider-selection,
  .slider-selection.tick-slider-selection {
    background-color: $color;
  }
  .slider-tick.in-selection,
  .slider-handle {
    background-color: darken($color, 10%);
  }
  .slider-handle {
    &:focus,
    &:active {
      box-shadow: 0 0 0 10px rgba($color, .15);
    }
  }
  .slider.slider-horizontal .slider-tick.triangle,
  .slider.slider-horizontal .slider-handle.triangle {
    border-bottom-color: darken($color, 10%);
  }
  .slider {
    .tooltip-inner {
      background-color: darken($color, 10%);
    }
    .tooltip.top .tooltip-arrow {
      border-top-color: darken($color, 10%);
    }
    .tooltip.right .tooltip-arrow {
      border-right-color: darken($color, 10%);
    }
    .tooltip.bottom .tooltip-arrow {
      border-bottom-color: darken($color, 10%);
    }
    .tooltip.left .tooltip-arrow {
      border-left-color: darken($color, 10%);
    }
  }
}
