//
// Variables
// --------------------------------------------------
//== Colors
//
//## Gray and brand colors for use across Bootstrap.
$gray-darker:            #191917; // EERIE BLACK
$gray-dark:              #303030; // JET
$gray:                   #5e5e5e; // EBONY
$gray-light:             #969696; // SPANISH GRAY
$gray-lighter:           #e8e8e8; // PLATINUM

// $gray-light to $gray-lighter
// [#969696 #ababab #c0c0c0 #d5d5d5 #e8e8e8]

// pastel
// DARK LIVER #584B53
$brand-primary:         #9474a9; // PURPLE MOUNTAIN MAJESTY
$brand-success:         #04C4A5; // AMAZONITE
$brand-info:            #4D9DE0; // CAROLINA BLUE
$brand-warning:         #C5906C; // WOOD BROWN or alternative #FFC300 - FLUORESCENT ORANGE
$brand-danger:          #C56C6C; // FUZZY WUZZY

$brand-default:         #eee; // darken(#f8f8f8, 2%) !default;
$brand-inverse:         $gray-dark !default;


//** Global color for active items (e.g., navs or dropdowns).
$component-active-color:    #fff;
//** Global background color for active items (e.g., navs or dropdowns).
$component-active-bg:       $brand-primary;


//== Scaffolding
//
// ## Settings for some of the most global styles.
//** Background color for `<body>`.
$body-bg:               #f8f8f8; // f8f8f8
//** Global text color on `<body>`.
$text-color:            $gray-dark;
//** Global text muted.
$text-muted:            $gray-light;
//** Global border color.
$border-color:            #EDEDED !default;
//** Global textual link color.
$link-color:            $brand-primary;
//** Link hover color set via `darken()` function.
$link-hover-color:      darken($link-color, 15%);


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:    'Roboto', sans-serif;
$font-size-base:            16px;
$blockquote-font-size:      1rem;

$line-height-base:          1.385;
$line-height-computed:      floor(($font-size-base * $line-height-base)); // ~22px

$headings-font-weight:      400;


//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).
$padding-base-vertical:     8px;
$padding-base-horizontal:   16px;

$padding-large-vertical:    12px;
$padding-large-horizontal:  20px;

$padding-small-vertical:    6px;
$padding-small-horizontal:  12px;

$padding-xs-vertical:       4px;
$padding-xs-horizontal:     6px;

$line-height-large:         1.3334; // extra decimals for Win 8.1 Chrome
$line-height-small:         1.363;

$border-radius-base:        4px;
$border-radius-large:       6px;
$border-radius-small:       2px;

$spacer:                   15px !default;


//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.
//** Padding for `<th>`s and `<td>`s.
$table-cell-padding:            10px;

//** Background color used for `.table-striped`.
$table-bg-accent:               #f4f4f4;
//** Background color used for `.table-hover`.
$table-bg-hover:                tint($brand-primary, 90%); // #f0f0f0;
$table-border-hover:            tint($brand-primary, 15%); // #f0f0f0;

//** Border color for table and cell borders.
$table-border-color:            darken($border-color, 2%);


//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-font-weight:                500;
$btn-border-width:               1px;

$btn-default-color:              $gray-dark;
$btn-default-bg:                 #fff;
$btn-default-border:             #e2e2e2;

$btn-secondary-color:              $text-color;
$btn-secondary-bg:                 $brand-default;
$btn-secondary-border:             $btn-default-border;

$btn-inverse-color:              #fff;
$btn-inverse-bg:                 $brand-inverse;
$btn-inverse-border:             transparent;

$btn-primary-color:              #fff;
$btn-primary-bg:                 $brand-primary;
$btn-primary-border:             transparent;

$btn-success-color:              #fff;
$btn-success-bg:                 $brand-success;
$btn-success-border:             transparent;

$btn-info-color:                 #fff;
$btn-info-bg:                    $brand-info;
$btn-info-border:                transparent;

$btn-warning-color:              #fff;
$btn-warning-bg:                 $brand-warning;
$btn-warning-border:             transparent;

$btn-danger-color:               #fff;
$btn-danger-bg:                  $brand-danger;
$btn-danger-border:              transparent;

$btn-link-disabled-color:        $gray-light;

// Allows for customizing button radius independently from global border radius
$btn-border-radius-base:         $border-radius-base;
$btn-border-radius-large:        $border-radius-large;
$btn-border-radius-small:        $border-radius-small;


//== Forms
//
//##

//** Default `.form-control` height
$input-height-base:              36px; // refer to button height
//** Large `.form-control` height
$input-height-large:             46px; // refer to button height
//** Small `.form-control` height
$input-height-small:             28px; // refer to button height

//** `<input disabled>` background color
$input-bg-disabled:              tint($gray-lighter, 50%);

//** Text color for `<input>`s
$input-color:                    $btn-default-color;
//** `<input>` border color
$input-border:                   $btn-default-border;
//** Border color for inputs on focus
$input-border-focus:             $brand-primary;
//** Placeholder text color
$input-color-placeholder:        #c0c0c0;

$legend-color:                   $gray-dark;
$legend-border-color:            #e2e2e2;

//** Background color for textual input addons
$input-group-addon-bg:           transparent;
//** Border color for textual input addons
$input-group-addon-border-color: $input-border;


//== Navbar
//
//##
$navbar-padding-vertical:          14px;
$navbar-collapse-max-height:       340px;

// Default
$navbar-default-color:             $gray;
$navbar-default-bg:                #fff;
$navbar-default-border:            $border-color;
// Navbar links
$navbar-default-link-color:                $gray;
$navbar-default-link-hover-color:          $text-color;
$navbar-default-link-hover-bg:             transparent;
$navbar-default-link-active-color:         $text-color;
$navbar-default-link-active-bg:            $brand-default;
$navbar-default-link-disabled-color:       $gray-light;
$navbar-default-link-disabled-bg:          transparent;
// Navbar brand label
$navbar-default-brand-color:               $gray-dark;
$navbar-default-brand-hover-color:         $gray-darker;
$navbar-default-brand-hover-bg:            transparent;
// Navbar toggle
$navbar-default-toggle-hover-bg:           $brand-default;
$navbar-default-toggle-icon-bar-bg:        $gray-dark;
$navbar-default-toggle-border-color:       transparent;

//=== Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-color:                      $gray-light;
$navbar-inverse-bg:                         #120E15;
$navbar-inverse-border:                     lighten($navbar-inverse-bg, 7.5%);
// Inverted navbar links
$navbar-inverse-link-color:                 $gray-light;
$navbar-inverse-link-hover-color:           #fff;
$navbar-inverse-link-hover-bg:              transparent;
$navbar-inverse-link-active-color:          $navbar-inverse-link-hover-color;
$navbar-inverse-link-active-bg:             darken($navbar-inverse-bg, 4%);
$navbar-inverse-link-disabled-color:        #444;
$navbar-inverse-link-disabled-bg:           transparent;
// Inverted navbar brand label
$navbar-inverse-brand-color:                $gray-lighter;
$navbar-inverse-brand-hover-color:          #fff;
$navbar-inverse-brand-hover-bg:             transparent;
// Inverted navbar toggle
$navbar-inverse-toggle-hover-bg:           mix(white, $navbar-inverse-bg, 10%);
$navbar-inverse-toggle-icon-bar-bg:        #fff;
$navbar-inverse-toggle-border-color:       transparent;


//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.
$state-success-text:             #038F79;
$state-success-bg:               tint($brand-success, 80%);
$state-success-border:           $brand-success;

$state-info-text:                #4081B8;
$state-info-bg:                  tint($brand-info, 80%);
$state-info-border:              $brand-info;

$state-warning-text:             #A25959;
$state-warning-bg:               tint($brand-warning, 80%);
$state-warning-border:           $brand-warning;

$state-danger-text:              #a94442;
$state-danger-bg:                tint($brand-danger, 80%);
$state-danger-border:            $brand-danger;


//== Navs
//
//##

//== Tabs
$nav-link-hover-bg:                         $brand-default;

$nav-tabs-border-color:                     darken($border-color, 2%);
$nav-tabs-bordered-border-width:            2px !default;

$nav-tabs-link-hover-border-color:          $nav-link-hover-bg;

$nav-tabs-active-link-hover-color:          $text-color;
$nav-tabs-active-link-hover-border-color:   $nav-tabs-border-color;

$nav-tabs-justified-link-border-color:      $nav-tabs-border-color;


//== Dropdowns
//
//##
//** Dropdown menu `border-color`.
$dropdown-border:                rgba(0,0,0,.05);
//** Dropdown menu `border-color` **for IE8**.
$dropdown-fallback-border:       $border-color;
//** Divider color for between dropdown items.
$dropdown-divider-bg:            $border-color;
//** Hover background for dropdown links.
$dropdown-link-hover-bg:         #f8f8f8;
//** Active background for dropdown links.
$dropdown-link-active-bg:         $component-active-bg;
// dropdown inverse
$dropdown-inverse-bg:         $brand-inverse !default;
$dropdown-inverse-color:      lighten($gray-light, 25%) !default;
// dropdown shadow
$dropdown-shadow:             0 2px 6px 0 rgba(#000, .1) !default;



//== Grid system
//
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         20px;


//== Pagination
//
//##

$pagination-color:                     $text-color;
$pagination-bg:                        transparent;
$pagination-border:                    transparent;

$pagination-hover-color:               $gray-darker;
$pagination-hover-bg:                  $brand-default;
$pagination-hover-border:              $brand-default;

$pagination-active-color:              #fff;
$pagination-active-bg:                 $brand-primary;
$pagination-active-border:             $brand-primary;

$pagination-disabled-color:            $gray-light;
$pagination-disabled-bg:               transparent;
$pagination-disabled-border:           transparent;


//== Pager
//
//##

$pager-bg:                             transparent;
$pager-border:                         transparent;
$pager-border-radius:                  30px;

$pager-hover-bg:                       $pagination-active-bg;

$pager-active-bg:                      $pagination-active-bg;
$pager-active-color:                   $pagination-active-color;


//== Tooltip
//
//##

//** Tooltip background color
$tooltip-bg:                  shade($brand-primary, 90%);
//** Tooltip arrow color
$tooltip-arrow-color:         $tooltip-bg;



//== Popovers
//
//##

//** Popover border color
$popover-border-color:                darken($border-color, 2%);
//** Popover fallback border color
$popover-fallback-border-color:       darken($border-color, 2%);

//** Popover title background color
$popover-title-bg:                    $brand-default;


//== Modals
//
//##
//** Modal content border color
$modal-content-border-color:                   rgba(#000,.1);
//** Modal content border color **for IE8**
$modal-content-fallback-border-color:          $gray-light;

//** Modal header border color
$modal-header-border-color:   darken($border-color, 2%);

//** Modal footer background color
$modal-footer-bg:             $body-bg;
//** Modal footer border color
$modal-footer-border-color:   $modal-header-border-color;


//== Thumbnails
//
//##

//** Thumbnail border color
$thumbnail-padding:           ($grid-gutter-width/2);
$thumbnail-heading-padding:   10px;

$thumbnail-bg:                #fff;
$thumbnail-border:            transparent;

$thumbnail-caption-padding:   ($grid-gutter-width/2);



//== Wells
//
//##
$well-bg:                     $brand-default;
$well-border:                 darken($border-color, 2%);


//== Breadcrumbs
//
//##
//** Breadcrumb background color
$breadcrumb-bg:                 transparent;
//** Breadcrumb color
$breadcrumb-color:              $gray-light;


//== List group
//
//##

//** `.list-group-item` border color
$list-group-border:             darken($border-color, 2%);


//== Panels
//
//##

$panel-heading-padding:       15px;
$panel-footer-padding:        $panel-heading-padding;

//** Border color for elements within panels
$panel-inner-border:          $border-color;
$panel-footer-bg:             #f8f8f8;

$panel-default-border:        darken($border-color, 2%);
$panel-default-heading-bg:    #fff;


//== Close
//
//##

// $close-font-weight:           normal;
$close-text-shadow:           none;


//== Code
//
//##

$code-bg:                     $brand-default;

$pre-bg:                      #fff;
$pre-border-color:            $panel-default-border;


//== Type
//
//##
$page-header-border-color:    $border-color;